<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="200mm"
    height="200mm"
    viewBox="0 0 200 200"
    version="1.1"
    id="svg8"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"
    sodipodi:docname="rosto-raiva.svg"
  >
    <defs id="defs2" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="0.35"
      inkscape:cx="526.797"
      inkscape:cy="339.32989"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1366"
      inkscape:window-height="703"
      inkscape:window-x="0"
      inkscape:window-y="0"
      inkscape:window-maximized="1"
    />
    <metadata id="metadata5">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-97)"
    >
      <circle
        style="fill:#e34a4a;stroke:#0000ff;stroke-width:0.26458332;stroke-opacity:0.83823529"
        id="path4597"
        cx="104.85059"
        cy="199.33092"
        r="92.226196"
        :style="`fill-opacity:${opacity ? 0 : 1};`"
      />
      <path
        inkscape:connector-curvature="0"
        d="m 100.31488,294.35417 c -53.230067,0 -96.5351183,-43.30505 -96.5351183,-96.53512 0,-53.23007 43.3050513,-96.53512 96.5351183,-96.53512 53.23007,0 96.53512,43.30505 96.53512,96.53512 0,53.23007 -43.30505,96.53512 -96.53512,96.53512 z m 0,-178.76846 c -45.343098,0 -82.233843,36.89049 -82.233843,82.23334 0,45.34285 36.890745,82.23384 82.233843,82.23384 45.3431,0 82.23334,-36.89074 82.23334,-82.23384 0,-45.3431 -36.88345,-82.23334 -82.23334,-82.23334 z"
        fill=""
        id="path3767"
        style="stroke-width:0.25139353"
      />
      <path
        inkscape:connector-curvature="0"
        d="m 61.15003,181.05764 c 0,6.14984 4.984128,11.14829 11.148047,11.14829 6.163918,0 11.148046,-4.99141 11.148046,-11.14804 0,-6.15663 -4.984128,-11.14805 -11.148046,-11.14805 -6.163919,0 -11.148047,4.99117 -11.148047,11.14805 z m 55.86166,0 c 0,6.14984 4.98412,11.14829 11.14804,11.14829 6.16392,0 11.14805,-4.99141 11.14805,-11.14804 0,-6.15663 -4.98413,-11.14805 -11.14805,-11.14805 -6.16392,0 -11.14804,4.99117 -11.14804,11.14805 z"
        fill=""
        id="path3769"
        style="stroke-width:0.25139353"
      />
      <path
        style="fill:none;stroke:#000000;stroke-width:11.265;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
        d="m 56.696427,236.52381 c 0,0 35.529763,-24.19048 87.690473,-1.51191"
        id="path820"
        inkscape:connector-curvature="0"
      />
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    opacity: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
