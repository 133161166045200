<template>
  <div>
    <vuestic-widget :headerText="$t(checklist.nome)">
      <vuestic-collapse v-for="area in areasDaChecklist" :key="area.id">
        <span slot="header">{{area.nome}}</span>
        <div slot="body">
          <div class="va-row">
            <div class="flex md12 xs12">
              <div class="pergunta"
v-for="pergunta in area.pergunta"
:key="pergunta.id">
                <h4>{{ perguntas(pergunta).pergunta}}</h4>
                <!-- <h4>{{perguntasDasAreas.find(d => d.id === pergunta)}}</h4> -->
                <tipos-de-resposta
                  v-on:sim="respostaSim(pergunta)"
                  v-on:nao="respostaNao(pergunta)"
                  v-on:naoAplica="respostaNaoAplica(pergunta)"
                  v-on:maisOuMenos="respostaMaisOuMenos(pergunta)"
                  :nao-se-aplica="perguntas(pergunta).nao_se_aplica"
                  :tipo="parseInt(perguntas(pergunta).tipos_de_respostas)"
                 />
                <hr class="style1">
              </div>
            </div>
          </div>

          <div class="va-row">
            <div class="flex md8"></div>
            <div class="flex md2">
              <button
                class="btn btn-pale btn-sm"
                @click="$router.push({name: 'lista-checklist-user'})"
              >Voltar</button>
            </div>
            <div class="flex md2">
              <button
                class="btn btn-primary btn-sm"
                @click="showStaticModal()"
              >{{'Enviar' | translate}}</button>
            </div>
          </div>
        </div>
      </vuestic-collapse>
      <br>
    </vuestic-widget>

    <vuestic-modal
      :show.sync="show"
      v-bind:small="true"
      v-bind:force="true"
      ref="staticModal"
      :cancelClass="'none'"
      :okText="'Fechar' | translate"
    >
      <div slot="title">{{'Confirmação' | translate}}</div>
      <div>{{'Sua resposta foi enviada com sucesso!' | translate}}</div>
    </vuestic-modal>
  </div>
</template>

<script>
import TiposDeResposta from '../../../vuestic-theme/vuestic-components/vuestic-tipo_de_resposta/todasAsRespostas.vue';
/* eslint-disable eqeqeq */

export default {
  name: 'cadastrar-pergunta',
  components: {
    TiposDeResposta,
  },
  data() {
    return {
      show: true,
      checklist: [],
      areasDaChecklist: [],
      perguntasDasAreas: [],
      perguntaSelecionada: {},
      todasAsRespostas: [],
      resposta: {
        tipo1: null,
        tipo2: null,
        tipo3: null,
        pergunta: null,
        acesso: null,
      },
    };
  },
  created() {
    this.getResposta();
    this.getPerguntasDasAreas();
    this.getChecklist();
    this.resetarResposta();
  },
  methods: {
    cadastrarResposta() {
      this.$axios.post('/api2/resposta.json', this.resposta).then((res) => {
        this.resetarResposta();
        this.todasAsRespostas.push(res.data);
        // alert(`Pergunta: ${this.perguntaSelecionada.pergunta} foi respondida`)
      });
    },
    filtroDeResposta(pergunta) {
      return this.perguntaSelecionada.id === pergunta.pergunta;
    },
    verificarSeJaFoiRespondida() {
      const resposta = this.todasAsRespostas.filter(this.filtroDeResposta);
      if (resposta.length > 0) {
        this.resposta.id = resposta[0].id;
        this.editarResposta();
      } else {
        this.cadastrarResposta();
      }
    },
    escolher(id, numero) {
      this.perguntaSelecionada = this.perguntasDasAreas.find(d => d.id === id);
      if (this.perguntaSelecionada.tipos_de_respostas === '1') {
        this.resposta.tipo1 = numero;
      } else if (this.perguntaSelecionada.tipos_de_respostas === '2') {
        this.resposta.tipo2 = numero;
      }
      this.verificarSeJaFoiRespondida();
    },
    perguntas(id) {
      // eslint-disable-next-line no-undef
      return _.filter(this.perguntasDasAreas, o => o.id == id)[0];
    },
    respostaSim(idDaPergunta) {
      this.resposta.pergunta = idDaPergunta;
      this.escolher(idDaPergunta, 1);
    },
    respostaNao(idDaPergunta) {
      this.resposta.pergunta = idDaPergunta;
      this.escolher(idDaPergunta, 2);
    },
    respostaNaoAplica(idDaPergunta) {
      this.resposta.pergunta = idDaPergunta;
      this.resposta.tipo3 = 4;
      this.cadastrarResposta();
    },
    respostaMaisOuMenos(idDaPergunta) {
      this.resposta.pergunta = idDaPergunta;
      this.escolher(idDaPergunta, 3);
    },
    getAreasDaChecklist() {
      this.$axios.get('/api/area_da_checklist/').then((res) => {
        this.areasDaChecklist = (res.data).filter(this.filtrarAreasPorChecklist);
      });
    },
    editarResposta() {
      this.$axios.put(`/api2/resposta/${this.resposta.id}.json`, this.resposta)
        .then(() => {
          this.resetarResposta();
          // alert('Foi editada com sucesso')
        });
    },
    filtrarAreasPorChecklist(areas) {
      return this.checklist.area_da_checklist.find(d => d == areas.id) > -1;
    },
    getChecklist() {
      this.$axios.get(`/api/checklist/${this.$router.history.current.params.checklist}.json`)
        .then((res) => {
          this.checklist = res.data;
          this.getAreasDaChecklist();
        });
    },
    getPerguntasDasAreas() {
      this.$axios.get('/api/pergunta.json').then((res) => {
        this.perguntasDasAreas = res.data;
      });
    },
    getResposta() {
      this.$axios.get('/api2/resposta.json').then((res) => {
        this.todasAsRespostas = res.data;
      });
    },
    resetarResposta() {
      this.resposta = {
        tipo1: null,
        tipo2: null,
        tipo3: null,
        pergunta: null,
        acesso: this.$router.history.current.params.acesso,
      };
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
  },
};
</script>
<style lang="scss">
$singleGutter: #{(19/16)}rem;

.pre-loader-container {
  height: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

hr.style1 {
  border-top: 1px solid #9b9b9b;
  opacity: 90%;
}

.pergunta .margin_left_right{
  margin-left: 0.5%;
}
.pergunta h4{
  margin-left: 1%;
}

</style>
