<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="200mm"
    height="200mm"
    viewBox="0 0 200 200"
    version="1.1"
    id="svg8"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"
    sodipodi:docname="rosto-sorrindo.svg"
  >
    <defs id="defs2" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="0.35"
      inkscape:cx="424.3132"
      inkscape:cy="825.54969"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1366"
      inkscape:window-height="703"
      inkscape:window-x="0"
      inkscape:window-y="0"
      inkscape:window-maximized="1"
    />
    <metadata id="metadata5">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-97)"
    >
      <circle
        style="fill:#4eb577;stroke:none;stroke-width:0.26458332;stroke-opacity:0.83823529"
        id="path4597"
        cx="104.85059"
        cy="199.33092"
        r="92.226196"
        :style="`fill-opacity:${opacity ? 0 : 1};`"
      />
      <g
        id="g3782"
        transform="matrix(0.25139354,0,0,0.25139354,-25.946016,61.954919)"
      >
        <path
          id="path3765"
          fill=""
          d="M 498.375,792.15 C 344.178,792.15 280.064,653.54 277.39,647.623 l 51.883,-23.324 c 2.048,4.522 51.257,110.961 169.074,110.961 126.122,-1.479 174.108,-106.467 176.07,-110.933 l 51.997,23.069 c -2.588,5.83 -65.138,142.848 -225.052,144.725 l -2.987,0.028 z"
          inkscape:connector-curvature="0"
        />
        <path
          id="path3767"
          fill=""
          d="m 502.244,924.444 c -211.74,0 -384,-172.26 -384,-384 0,-211.74 172.26,-384 384,-384 211.74,0 384,172.26 384,384 0,211.74 -172.26,384 -384,384 z m 0,-711.11 c -180.367,0 -327.112,146.744 -327.112,327.11 0,180.366 146.745,327.112 327.112,327.112 180.367,0 327.11,-146.745 327.11,-327.112 0,-180.367 -146.716,-327.11 -327.11,-327.11 z"
          inkscape:connector-curvature="0"
        />
        <path
          id="path3769"
          fill=""
          d="m 346.453,473.77 c 0,24.463 19.826,44.346 44.345,44.346 24.519,0 44.345,-19.855 44.345,-44.345 0,-24.49 -19.826,-44.345 -44.345,-44.345 -24.519,0 -44.345,19.854 -44.345,44.345 z m 222.208,0 c 0,24.463 19.826,44.346 44.345,44.346 24.519,0 44.345,-19.855 44.345,-44.345 0,-24.49 -19.826,-44.345 -44.345,-44.345 -24.519,0 -44.345,19.854 -44.345,44.345 z"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    opacity: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style>
</style>
