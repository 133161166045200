<template>
  <div class="container">
    <div class="row">

      <template
        class=""
        v-if="tipo === 2"
      >
        <rosto-sorrindo
          class="icons"
          :opacity="resposta.tipo2 !== 1"
        />

        <rosto-raiva
          class="icons"
          :opacity="resposta.tipo2 !== 2"
        />
      </template>
      <template v-else-if="tipo === 3">
        <div
          class="box-question class-sim mr-2"
          :class="{enable: resposta.tipo2 === 1}"
        >
          Sim
        </div>
        <div
          class="box-question class-nao"
          :class="{enable: resposta.tipo2 === 2}"
        >
          Não
        </div>
      </template>
      <template v-else-if="tipo === 1">
        <rosto-sorrindo
          class="icons"
          :opacity="resposta.tipo1 !== 1"
        />
        <rosto-sem-expressao
          class="icons"
          :opacity="resposta.tipo1 !== 3"
        />
        <rosto-raiva
          class="icons"
          :opacity="resposta.tipo1 !== 2"
        />
      </template>
      <template v-else-if="tipo === 4">
        <h5
          v-if="!resposta.tipo4"
          class="mt-2 mr-1"
        >Campo de Texto</h5>

        <p
          v-else
          class="ellipsis text-center mt-2 mb-2"
        > {{resposta.tipo4}} </p>
      </template>
      <template v-if="naoSeAplica && !resposta.tipo4">
        <div
          class="box-question class-n-a ml-2"
          :class="{circle: tipo !== 3, enable: resposta.tipo3 === 4 }"
        >
          <strong>N/A</strong>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import rostoSorrindo from '@/assets/vue-svg/rosto-sorrindo.vue';
import rostoRaiva from '@/assets/vue-svg/rosto-raiva.vue';
import rostoSemExpressao from '@/assets/vue-svg/rosto-sem-expressao.vue';

export default {
  name: 'TodasAsRespostas',
  components: { rostoSorrindo, rostoRaiva, rostoSemExpressao },
  data() {
    return {
      classeSim: '',
      classeNao: '',
      classeNSeAplica: '',
      classeMaisOuMenos: '',
    };
  },
  props: {
    naoSeAplica: {
      type: Boolean,
      default: true,
    },
    tipo: {
      type: Number,
      default: 0,
    },
    resposta: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    sim() {
      this.$emit('sim');
      this.classeSim = 'focus';
      this.classeNao = '';
      this.classeNSeAplica = '';
      this.classeMaisOuMenos = '';
    },
    nao() {
      this.$emit('nao');
      this.classeNao = 'focus';
      this.classeSim = '';
      this.classeNSeAplica = '';
      this.classeMaisOuMenos = '';
    },
    maisOuMenos() {
      this.$emit('maisOuMenos');
      this.classeMaisOuMenos = 'focus';
      this.classeSim = '';
      this.classeNao = '';
      this.classeNSeAplica = '';
    },
    naoAplica() {
      this.$emit('naoAplica');
      this.classeNSeAplica = 'focus';
      this.classeSim = '';
      this.classeNao = '';
      this.classeMaisOuMenos = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.focus {
	color: darken(theme-color('primary'), 15%);
}
.box-question {
	border-radius: 10px;
	width: 85px;
	border: solid 2px #4eb577;
	color: #4eb577;
	padding: 0.625rem 0;
	font-size: 85%;
	line-height: 1.25;
	user-select: none;
	text-transform: uppercase;
	text-align: center;
}
.class-n-a {
	border: solid 2px #aa6cd9;
	color: #aa6cd9;
	height: fit-content;
	&.enable {
		color: #fff;
		background-color: #aa6cd9;
	}
}
.class-sim {
	border: solid 2px #4eb577;
	color: #4eb577;
	background: #fff;
	&.enable {
		color: #fff;
		background-color: #4eb577;
	}
}
.class-nao {
	border: solid 2px #e34a4a;
	color: #e34a4a;
	background-color: #fff;
	&.enable {
		color: #fff;
		background-color: #e34a4a;
	}
}
.ellipsis:hover {
	overflow: visible;
}
.icons {
	width: 50px !important;
	height: 50px !important;
}
.circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 0;
	line-height: 2;
	margin: 0 !important;
}
</style>
